import React from 'react';
import Header from '../../common/Header/Header';
import ServicesBanner from '../ServicesBanner/ServicesBanner';
import HowWeWork from '../HowWeWork/HowWeWork';
import ExploreOurAllServices from '../ExploreOurAllServices/ExploreOurAllServices';
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer';
import OurClients from '../OurClients/OurClients';

export default function Services() {
  return (
    <>
        <Header />
        <ServicesBanner />
        <HowWeWork />
        <ExploreOurAllServices />
        <OurClients />
        <ContactUs />
        <Footer />
    </>
  )
}
