import React from 'react';
import Header from '../../common/Header/Header';
import Banner from '../Banner/Banner';
import ExploreOurServices from '../ExploreOurServices/ExploreOurServices';
import MeetOurProduct from '../MeetOurProduct/MeetOurProduct';
import AboutUs from '../AboutUs/AboutUs';
import WhyChooseUs from '../WhyChooseUs/WhyChooseUs';
import OurClients from '../OurClients/OurClients';
import OurLeaders from '../OurLeaders/OurLeaders';
import WhyRajasri from '../WhyRajasri/WhyRajasri';
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer';

export default function Main() {
  return (
    <>
        <Header />
        <Banner />
        <ExploreOurServices />
        <MeetOurProduct />
        <AboutUs />
        <WhyChooseUs />
        <OurLeaders />
        <WhyRajasri />
        <OurClients />
        <ContactUs />
        <Footer />
    </>
  )
}
