import React from 'react';
import {
    Box,
    Button
} from '@mui/material';
import '../AllButtonComponent/AllButtonComponent.css';

export default function AllButtonComponent({AllButtons, buttonValue, ButtonStyles, handleSubmit, disabled}) {
  return (
    <>
      <section>
        <Box>
          <Button
          variant={ButtonStyles}
          size="medium"
          disableElevation
          className={AllButtons}
          onClick={handleSubmit}
          disabled={disabled}
          >
          {buttonValue}
          </Button>
        </Box>
      </section>
    </>
  )
}
