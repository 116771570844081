import React from 'react';
import {
    Box,
    Container,
    Typography,
    Grid
} from '@mui/material';
import '../OurLeaders/OurLeaders.css';
import { motion } from 'framer-motion';

export default function OurLeaders({index}) {

  const FounderDirector = require('../../assets/Rali-founder-director-bg.png');
  const Director = require('../../assets/Rm-director-bg.png');
  const ManagingDirector = require('../../assets/NS-managing-director-bg.png');

  return (
    <>
        <section>
            <Box sx={{ py: 8 }}>
                <Container>
                    <Box sx={{ textAlign: 'center' }}>
                        <motion.div
                        initial={{
                            opacity: 0,
                            // if odd index card,slide from right instead of left
                            x: index % 1 === 0 ? 50 : -50
                          }}
                          whileInView={{
                            opacity: 1,
                            x: 0, // Slide in to its original position
                            transition: {
                              duration: 1, // Animation duration
                              ease: "easeOut"
                            }
                          }}
                          viewport={{ once: true }}
                        >
                            <Typography variant='h4' sx={{ fontWeight: '800', color : '#383745', pb : 4 }}>
                                Our Thought leaders <span className='sectionTitle'>You’ll Work With!</span>
                            </Typography>
                        </motion.div>
                        <Container maxWidth="md" sx={{ pb: 8 }}>
                            <motion.div
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                y: index % 1 === 0 ? -50 : 50
                              }}
                              whileInView={{
                                opacity: 1,
                                y: 0, // Slide in to its original position
                                transition: {
                                  duration: 1, // Animation duration
                                  ease: "easeOut"
                                }
                              }}
                              viewport={{ once: true }}
                            >
                                <Typography sx={{ color: '#808080' }}>
                                  Our leaders with 25+ years of expertise in IT industry provide an excellent technical management of the project and superficial strategy for execution.
                                </Typography>
                            </motion.div>
                        </Container>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ position: 'relative' }}>
                                    <Box>
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeOut"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <img
                                            className='leadersImage'
                                            src={String(FounderDirector)}
                                            alt="founder-director"
                                            />
                                        </motion.div>
                                        <Box sx={{ pt: 4 }}>
                                            <motion.div
                                            initial={{
                                                opacity: 0,
                                                // if odd index card,slide from right instead of left
                                                y: index % 1 === 0 ? -50 : 50
                                              }}
                                              whileInView={{
                                                opacity: 1,
                                                y: 0, // Slide in to its original position
                                                transition: {
                                                  duration: 1, // Animation duration
                                                  ease: "easeOut"
                                                }
                                              }}
                                              viewport={{ once: true }}
                                            >
                                                <Typography variant='h5' sx={{ color: '#0876C1', fontWeight: '500', pb: 2, fontSize: '1.25rem' }}>
                                                    Rali Panchanatham
                                                </Typography>
                                                <Typography sx={{ color: '#808080' }}>
                                                    Founder/Director
                                                </Typography>
                                            </motion.div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ position: 'relative' }}>
                                    <Box>
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeOut"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <img
                                            className='leadersImage'
                                            src={String(Director)}
                                            alt="director"
                                            />
                                        </motion.div>
                                        <Box sx={{ pt: 4 }}>
                                            <motion.div
                                            initial={{
                                                opacity: 0,
                                                // if odd index card,slide from right instead of left
                                                y: index % 1 === 0 ? -50 : 50
                                              }}
                                              whileInView={{
                                                opacity: 1,
                                                y: 0, // Slide in to its original position
                                                transition: {
                                                  duration: 1, // Animation duration
                                                  ease: "easeOut"
                                                }
                                              }}
                                              viewport={{ once: true }}
                                            >
                                                <Typography variant='h5' sx={{ color: '#0876C1', fontWeight: '500', pb: 2, fontSize: '1.25rem' }}>
                                                    Manikandan Raghavan
                                                </Typography>
                                                <Typography sx={{ color: '#808080' }}>
                                                    Director
                                                </Typography>
                                            </motion.div>
                                        </Box>                                    
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{ position: 'relative' }}>
                                    <Box>
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeOut"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <img
                                            className='leadersImage'
                                            src={String(ManagingDirector)}
                                            alt="managing-director"
                                            />
                                        </motion.div>
                                        <Box sx={{ pt: 4 }}>
                                            <motion.div
                                            initial={{
                                                opacity: 0,
                                                // if odd index card,slide from right instead of left
                                                y: index % 1 === 0 ? -50 : 50
                                              }}
                                              whileInView={{
                                                opacity: 1,
                                                y: 0, // Slide in to its original position
                                                transition: {
                                                  duration: 1, // Animation duration
                                                  ease: "easeOut"
                                                }
                                              }}
                                              viewport={{ once: true }}
                                            >
                                                <Typography variant='h5' sx={{ color: '#0876C1', fontWeight: '500', pb: 2, fontSize: '1.25rem' }}>
                                                    Norihito Sakaguchi
                                                </Typography>
                                                <Typography sx={{ color: '#808080' }}>
                                                    Managing Director
                                                </Typography>
                                            </motion.div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </section>
    </>
  )
}
