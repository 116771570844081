import React from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
    Link
} from '@mui/material';
import AllButtonComponent from '../../common/AllButtonComponent/AllButtonComponent';
import { motion } from 'framer-motion';

export default function BoostYourBusiness({index}) {

    const BoostYourBusinessIllustration = require('../../assets/boost-your-business-bg.png');

  return (
    <>
        <section>
            <Box sx={{ backgroundColor: '#383745', py: 8, mb: 8 }}>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            <motion.div
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                x: index % 1 === 0 ? -50 : -50
                              }}
                              whileInView={{
                                opacity: 1,
                                x: 0, // Slide in to its original position
                                transition: {
                                  duration: 1, // Animation duration
                                  ease: "easeOut"
                                }
                              }}
                              viewport={{ once: true }}
                            >
                                <Box>
                                    <Typography variant='h4' sx={{ fontWeight: '800', color: '#FFFFFF', pb: 4 }}>Do You Want To Boost Your Business?</Typography>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        y: index % 1 === 0 ? -50 : 50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        y: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                      <Link href="#contactUs" sx={{ textDecoration: 'none' }}>
                                        <AllButtonComponent AllButtons="getStartedButton" buttonValue="Contact Us" ButtonStyles="outlined" />
                                      </Link>
                                    </motion.div>
                                </Box>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <motion.div
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                y: index % 1 === 0 ? -50 : 50
                              }}
                              whileInView={{
                                opacity: 1,
                                y: 0, // Slide in to its original position
                                transition: {
                                  duration: 1, // Animation duration
                                  ease: "easeOut"
                                }
                              }}
                              viewport={{ once: true }}
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <img
                                    className='ourProduct'
                                    src={String(BoostYourBusinessIllustration)}
                                    alt="Our-product"
                                    />
                                </Box>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </section>
    </>
  )
}
