import React from 'react';
import {
    Box
} from '@mui/material';

export default function CarouselItem({ imgUrl, imgTitle }) {
  return (
    <>
        <Box className="carousel-card">
            <img src={imgUrl} alt={imgTitle} />
        </Box>
    </>
  )
}
