import React from 'react';
import {
    Box
} from '@mui/material';
import { cardDetails } from '../../common/CarouselConfig/CarouselConfig';
import CarouselItem from '../CarouselItem/CarouselItem';
import '../AutoplayCarousel/AutoplayCarousel.css';

export default function AutoplayCarousel() {
  return (
    <>
        <Box className="carousel-container">
            <Box className="carousel-track">
                {Object.keys(cardDetails).map((detailKey) => {
                return (
                    <CarouselItem
                        imgUrl={cardDetails[detailKey].imgUrl}
                        imgTitle={cardDetails[detailKey].title}
                    ></CarouselItem>
                );
                })}
                {Object.keys(cardDetails).map((detailKey) => {
                return (
                    <CarouselItem
                        imgUrl={cardDetails[detailKey].imgUrl}
                        imgTitle={cardDetails[detailKey].title}
                    ></CarouselItem>
                );
                })}
            </Box>
        </Box>
    </>
  )
}
