const clientLogo1 = require('../../assets/client-logo-bg-1.png');
const clientLogo2 = require('../../assets/client-logo-bg-2.png');
const clientLogo3 = require('../../assets/client-log-bg-3.png');
const clientLogo4 = require('../../assets/client-logo-bg-4.png');
const clientLogo5 = require('../../assets/client-logo-bg-5.png');
const clientLogo6 = require('../../assets/client-log-bg-6.png');
const clientLogo7 = require('../../assets/client-log-bg-7.png');
const clientLogo8 = require('../../assets/client-log-bg-8.png');
const clientLogo9 = require('../../assets/client-logo-bg-9.png');


export const cardDetails = {
    0: {
        imgUrl: clientLogo1,
        title: "client Logo"
    },

    1: {
        imgUrl: clientLogo2,
        title: "client Logo"
    },

    2: {
        imgUrl: clientLogo3,
        title: "client Logo"
    },

    3: {
        imgUrl: clientLogo4,
        title: "client Logo"
    },

    4: {
        imgUrl: clientLogo5,
        title: "client Logo"
    },

    5: {
        imgUrl: clientLogo6,
        title: "client Logo"
    },

    6: {
        imgUrl: clientLogo7,
        title: "client Logo"
    },

    7: {
        imgUrl: clientLogo8,
        title: "client Logo"
    }, 
    
    8: {
        imgUrl: clientLogo9,
        title: "client Logo"
    }, 
}