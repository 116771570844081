import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import "../CareerModel/CareerModel.css";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import AllButtonComponent from "../AllButtonComponent/AllButtonComponent";
import { produce } from "immer";
import axios from "axios";
import ToastMessage from "../ToastMessage/ToastMessage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CareerModel({ opened, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const initialState = {
    from: "info@rajasri.net",
    // to: "maruthupandiyanm@rajasri.net",
    to: "hr@rajasri.net",
    password: "18R@j@sri",
    careerSubject: "Career request form",
    body: {
      name: "",
      mobileNo: "",
      email: "",
      job: "",
      message: "",
    },
    error: {
      body: {
        name: "",
        mobileNo: "",
        email: "",
        job: "",
        message: "",
      },
    },
  };

  const [getEmailForm, setEmailForm] = useState(initialState);

  const [loading, setLoading] = React.useState(false);

  const [isToastOpened, setIsToastOpened] = useState(false);

  const [responseMessage, setResponseMessage] = useState("");

  const sendEmail = async (data) => {
    try {
      if (!loading) {
        setLoading(true);

        const response = await axios.post(
          "https://enminvithaigal.click/Rajasri_Email/api/Email/send",
          data
        );
        const responseData = response.data;
        const responseDataMessage = setResponseMessage(responseData.message);

        setEmailForm(initialState);
        setLoading(false);
        handleClose();
        setIsToastOpened(true);
        setTimeout(() => {
          setIsToastOpened(false);
        }, 3000);

        return responseDataMessage;
      }
    } catch (error) {
      setResponseMessage("Message sent failed!");
      setEmailForm(initialState);
      setIsToastOpened(true);
      setTimeout(() => {
        setIsToastOpened(false);
      }, 3000);
      setLoading(false);
    }
    console.log(responseMessage);
  };

  const handleCloseBtn = () => {
    setIsToastOpened(false);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setEmailForm((prevState) =>
      produce(prevState, (draft) => {
        draft.body[name] = value;
        draft[name] = value;
        draft.error.body[name] = "";
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (getEmailForm.body.name.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.name = "Please enter a name";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.name = "";
        })
      );
    }
    if (getEmailForm.body.mobileNo.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.mobileNo = "Please enter a mobile number";
        })
      );
      return false;
    } else if (getEmailForm.body.mobileNo.length !== 10) {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.mobileNo = "Please enter a valid mobile number";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.mobileNo = "";
        })
      );
    }

    if (getEmailForm.body.email.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.email = "Please enter an email";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.email = "";
        })
      );
    }

    if (getEmailForm.body.job.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.job = "Please enter a job";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.job = "";
        })
      );
    }

    const data = {
      from: "info@rajasri.net",
      to: "hr@rajasri.net",
      password: "18R@j@sri",
      subject: "",
      careerSubject: "Career request form",
      body: {
        name: getEmailForm.body.name,
        mobileNo: getEmailForm.body.mobileNo,
        email: getEmailForm.body.email,
        job: getEmailForm.body.job,
        message:
          getEmailForm.body.message !== "" ? getEmailForm.body.message : "",
      },
    };
    sendEmail(data);
  };

  return (
    <>
      <Box>
        <ToastMessage
          message={responseMessage}
          IsOpened={isToastOpened}
          toastClassName={"careermessage"}
          handleClose={handleCloseBtn}
        />
        <Dialog
          fullScreen={fullScreen}
          open={opened}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Career Request Form"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box className="careerForm" sx={{ textAlign: "center", pt: 1 }}>
              <Box>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  label="Name"
                  placeholder="Enter your name"
                  variant="outlined"
                  name="name"
                  value={getEmailForm.body.name}
                  onChange={onInputChange}
                  error={Boolean(getEmailForm.error.body.name)}
                  helperText={getEmailForm.error.body.name}
                />
              </Box>
              <Box>
                <TextField
                  className="mobileNo"
                  fullWidth
                  sx={{ mb: 2 }}
                  type="number"
                  label="Mobile number"
                  placeholder="Enter your mobile number"
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  name="mobileNo"
                  value={getEmailForm.body.mobileNo}
                  onChange={onInputChange}
                  error={Boolean(getEmailForm.error.body.mobileNo)}
                  helperText={getEmailForm.error.body.mobileNo}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  variant="outlined"
                  name="email"
                  value={getEmailForm.body.email}
                  onChange={onInputChange}
                  error={Boolean(getEmailForm.error.body.email)}
                  helperText={getEmailForm.error.body.email}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  label="Job"
                  placeholder="Job you like ..."
                  variant="outlined"
                  name="job"
                  value={getEmailForm.body.job}
                  onChange={onInputChange}
                  error={Boolean(getEmailForm.error.body.job)}
                  helperText={getEmailForm.error.body.job}
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  variant="outlined"
                  label="Message"
                  multiline
                  rows={3}
                  placeholder="Enter your message"
                  name="message"
                  value={getEmailForm.body.message}
                  onChange={onInputChange}
                />
              </Box>
              <Box>
                <AllButtonComponent
                  AllButtons="secondaryButton"
                  buttonValue="Send Message"
                  ButtonStyles="contained"
                  disabled={loading}
                  handleSubmit={handleSubmit}
                />
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#fff",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Box>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Disagree
            </Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions> */}
        </Dialog>
      </Box>
    </>
  );
}
