import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
    Container,
    Link,
    Toolbar,
    IconButton, 
    Drawer, 
    CssBaseline, 
    Box, 
    Slide, 
    AppBar, 
    useScrollTrigger
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import '../Header/Header.css';
import AllButtonComponent from '../AllButtonComponent/AllButtonComponent';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

export default function Header(props, {index}) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleDrawerClose = () => {
    setMobileOpen(true);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} className="header-menu-mobile" sx={{ py : 2 }}>
      <DrawerHeader sx={{ pr: 4 }}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <NavLink className='header-menu' to="/" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>Home</NavLink>
      <Link className='header-menu' exact href="#exploreOurServices" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>What We Do</Link>
      <Link className='header-menu' exact href="#ourClients" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>Clients</Link>
      <Link className='header-menu' exact href="#contactUs" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>Contact Us</Link>
      <NavLink className='header-menu' exact to="/our-company" underline='none' sx={{ color : '#010101', fontWeight : 500 }}>Company</NavLink>
      <Box sx={{ flexGrow: 1, display: { xs : 'block', sm : 'block'}, pl: 2, pt: 2 }}>
        <Link exact href="#contactUs">
          <AllButtonComponent AllButtons="primaryContainedButton" buttonValue="Get In Touch" ButtonStyles="contained" />
        </Link>
      </Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const SiteLogo = require('../../assets/site-header-logo.png');


  return (
    <>
      <section>
        <Box>
            <CssBaseline />
            <HideOnScroll {...props}>
            <AppBar component="nav" color="transparent" elevation={0}  sx={{ py: 1 }} >
                <Container>
                  <motion.div
                  
                  initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    y: index % 1 === 0 ? 50 : -50
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                      ease: "easeOut"
                    }
                  }}
                  viewport={{ once: true }}>
                    <Toolbar sx={{ flexGrow : 1, justifyContent : 'space-between', alignItems : 'center', backgroundColor: '#ECF7FF', borderRadius: 12, px: 2 }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { xs: 'block', sm: 'none', md: 'none' } }}>
                            <MenuIcon />
                        </IconButton>
                        <Box component="div" sx={{ flexGrow: 1, display: { xs : 'block', sm : 'block', width : '200px' } }}>
                          <NavLink exact to="/">
                            <img 
                                className='site-logo'
                                src={String(SiteLogo)}
                                alt="Site-logo"
                            />
                          </NavLink>                    
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                            <NavLink className='header-menu' exact to="/" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>Home</NavLink>
                            <Link className='header-menu' exact href="#exploreOurServices" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>What We Do</Link>
                            <Link className='header-menu' exact href="#ourClients" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>Clients</Link>
                            <Link className='header-menu' exact href="#contactUs" underline='none' sx={{ px : 3, color : '#010101', fontWeight : 500 }}>Contact Us</Link>
                            <NavLink className='header-menu' exact to="/our-company" underline='none' sx={{ TextDecoder: 'none', px : 3, color : '#010101', fontWeight : 500 }}>Company</NavLink>
                        </Box>
                        <Link exact href="#contactUs" sx={{ flexGrow: 1, paddingLeft: '0px !important', display: { xs : 'none', sm : 'none', md: 'block'}, textAlign : 'right' }}>
                            <AllButtonComponent AllButtons="primaryContainedButton" buttonValue="Get In Touch" ButtonStyles="contained" sx={{ width: '100%' }} />
                        </Link>
                    </Toolbar>
                  </motion.div>
                </Container>
            </AppBar>
            </HideOnScroll>
            <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                }}
            >
            {drawer}
            </Drawer>
        </Box>
        </Box>
      </section>
    </>
  )
}
