import React from 'react';
import {
    Box,
    Container,
    Grid,
    Typography
} from '@mui/material';
import '../MeetOurProduct/MeetOurProduct.css';
import AllButtonComponent from '../../common/AllButtonComponent/AllButtonComponent';
import { motion } from 'framer-motion';

const meetOurProduct = 'https://enminvithaigal.in/'

export default function MeetOurProduct({index}) {

  const OurProductIllustration = require('../../assets/meet-our-product-bg.png');

  return (
    <>
        <section>
            <Box sx={{ backgroundColor: '#0876C1', py: 8 }}>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            <motion.div
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                y: index % 1 === 0 ? 50 : -50
                              }}
                              whileInView={{
                                opacity: 1,
                                y: 0, // Slide in to its original position
                                transition: {
                                  duration: 1, // Animation duration
                                  ease: "easeOut"
                                }
                              }}
                              viewport={{ once: true }}
                            >
                                <Box>
                                    <Typography variant='h4' sx={{ fontWeight: '800', color: '#FFFFFF', pb: 4 }}>Meet Our Products</Typography>
                                    <Typography sx={{ color: '#FFFFFF', pb: 4 }}>
                                      Our products,developed with latest technology provide feasible solution to present technical hurdles, catering the utmost needs enrouting to customer delight.
                                    </Typography>
                                    <Box onClick={() => window.open(meetOurProduct, '_blank')}>
                                      <AllButtonComponent  AllButtons="getStartedButton" buttonValue="Get Started" ButtonStyles="outlined" />
                                    </Box>
                                </Box>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <motion.div
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                y: index % 1 === 0 ? -50 : 50
                              }}
                              whileInView={{
                                opacity: 1,
                                y: 0, // Slide in to its original position
                                transition: {
                                  duration: 1, // Animation duration
                                  ease: "easeOut"
                                }
                              }}
                              viewport={{ once: true }}
                            >
                                <Box 
                                sx={{ textAlign: 'center' }}>
                                    <img
                                    className='ourProduct'
                                    src={String(OurProductIllustration)}
                                    alt="Our-product"
                                    />
                                </Box>
                            </motion.div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </section>
    </>
  )
}
