import React from 'react';
import {
    Box,
    Typography,
    Container,
    Grid
} from '@mui/material';
import { motion } from "framer-motion";

export default function OurMission() {
  return (
    <>
        <section>
            <Box sx={{ py: 8 }}>
                <Container>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <motion.div
                                initial={{opacity: 0, y: 300}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 1, ease: "easeOut"}}
                                >
                                    <Box sx={{ backgroundColor: '#EEB39C', p: 4, borderRadius: 4 }}>
                                        <Typography variant='h5' sx={{ color: '#ffffff', pb: 2 }}>
                                            Our Mission
                                        </Typography>
                                        <Typography sx={{ color: '#ffffff' }}>
                                            Delivering customized software development services with technically skilled resources in a profound way. Reliable IT solutions configured with technical agility since 2000.
                                        </Typography>
                                    </Box>
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <motion.div
                                initial={{opacity: 0, y: 200}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 1, ease: "easeOut"}}
                                >
                                    <Box sx={{ backgroundColor: '#F7CE76', p: 4, borderRadius: 4 }}>
                                        <Typography variant='h5' sx={{ color: '#ffffff', pb: 2 }}>
                                            Our Vision
                                        </Typography>
                                        <Typography sx={{ color: '#ffffff' }}>
                                            A single gateway for all your multi-faceted business requirements. We aspire to deliver custom or tailor made services to the clients in adherence with quality and durability.
                                        </Typography>
                                    </Box>
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <motion.div 
                                initial={{opacity: 0, y: 100}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 1, ease: "easeOut"}}
                                >
                                    <Box sx={{ backgroundColor: '#9A769A', p: 4, borderRadius: 4 }}>
                                        <Typography variant='h5' sx={{ color: '#ffffff', pb: 2 }}>
                                            Our Value
                                        </Typography>
                                        <Typography sx={{ color: '#ffffff' }}>
                                            Our Value is to meet real world business needs. We associate ourselves with technology for enhancing your professional needs and engrave your path to success.
                                        </Typography>
                                    </Box>
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </section>
    </>
  )
}
