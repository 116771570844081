import React from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
} from '@mui/material';
import { Icon } from '@iconify/react';
import AllButtonComponent from '../../common/AllButtonComponent/AllButtonComponent';
import '../ExploreOurServices/ExploreOurServices.css';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function ExploreOurServices({index}) {
  return (
    <>
        <section id="exploreOurServices">
            <Box sx={{ py: 8 }}>
                <motion.div
                initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    y: index % 1 === 0 ? -50 : 50
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                      ease: "easeOut"
                    }
                  }}
                  viewport={{ once: true }}
                >
                    <Box sx={{ textAlign : 'center', pb: 6 }}>
                        <Typography className='subHeaderTitle' variant="h4" gutterBottom sx={{ fontWeight : '600', color : '#010101', pb : 2 }}>
                            What We <span className='sectionTitle'>Do</span>
                        </Typography>
                        <Container maxWidth="md" sx={{ pb : 3 }}>
                            <Typography  sx={{ color : '#808080' }}>
                                Our products,developed with latest technology provide feasible solution to present technical hurdles, catering the utmost needs enrouting to customer delight.
                            </Typography>
                        </Container>
                        <NavLink className="btnExploreAllOurServices" to='/explore-our-services' sx={{ textDecoration: 'none' }}>
                            <AllButtonComponent AllButtons="secondaryButton" buttonValue="Explore All Our Services" ButtonStyles="contained"  />
                        </NavLink>
                    </Box>
                </motion.div>
            </Box>
            <Box>
                <Container sx={{ mb: 6 }}>
                    <motion.div
                    initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        y: index % 1 === 0 ? -50 : 50
                      }}
                      whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: 1, // Animation duration
                          ease: "easeOut"
                        }
                      }}
                      viewport={{ once: true }}
                    >
                        <Grid container rowSpacing={2} className='con'>
                            <Grid item xs={12} sm={6} md={3} className="card">
                                    <Box className="card_s">
                                        <Typography className='cardTitle'>
                                            <Icon icon="eos-icons:ai" width={36} height={36} />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                                Artificial Intelligence
                                                <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                            </Box>
                                        </Typography>
                                        <Typography className='contentCardHover'>
                                            AI,a versatile technology - inevitable for transforming life incredibly. AI promotes automation, controls human oversights, reduces the risk. AI delivers the best.
                                        </Typography>
                                    </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="card">
                                    <Box className="card_s">
                                        <Typography className='cardTitle'>
                                            <Icon icon="tabler:cloud-computing" width={36} height={36} />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                                Cloud Computing
                                                <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                            </Box>
                                        </Typography>
                                        <Typography className='contentCardHover'>
                                            Cloud computing focusses on instantaneous availability of system resources on demand especially in data storage without direct active management by the user via the data centers.  
                                        </Typography>
                                    </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="card">
                                    <Box className="card_s">
                                        <Typography className='cardTitle'>
                                            <Icon icon="mingcute:appstore-line" width={36} height={36} />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                                App Development
                                                <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                            </Box>
                                        </Typography>
                                        <Typography className='contentCardHover'>
                                            We develop Webapps and mobile apps to seamlessly cater all business needs. With many testimonials from our clients, the Apps we develop are of customer's delight.
                                        </Typography>
                                    </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="card">
                                    <Box className="card_s">
                                        <Typography className='cardTitle'>
                                            <Icon icon="mdi:web" width={36} height={36} />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                                Web Experts
                                                <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                            </Box>
                                        </Typography>
                                        <Typography className='contentCardHover'>
                                            Responsive Websites and online portals suiting to customer needs prove our web expertise in the areas of order management, customer management etc.,
                                        </Typography>
                                    </Box>
                            </Grid>
                        </Grid>
                    </motion.div>
                </Container>
            </Box>
        </section>
    </>
  )
}
