import React from 'react';
import {
    Box,
    Container,
    Typography
} from '@mui/material';
import '../OurClients/OurClients.css';
import AutoplayCarousel from '../AutoplayCarousel/AutoplayCarousel';
import { motion } from 'framer-motion';

export default function OurClients({index}) {
  return (
    <>
        <section id='ourClients'>
            <Box sx={{ backgroundColor: '#0876C1', py: 8 }}>
                <Container maxWidth="xl">
                    <motion.div
                    initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        y: index % 1 === 0 ? -50 : 50
                      }}
                      whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: 1, // Animation duration
                          ease: "easeOut"
                        }
                      }}
                      viewport={{ once: true }}
                    >
                        <Box>
                            <Typography variant='h4' sx={{ fontWeight: '800', textAlign: 'center', pb: 6, color: '#ffffff' }}>Our Clients</Typography>
                        </Box>
                    </motion.div>
                    <AutoplayCarousel />
                </Container>
            </Box>
        </section>
    </>
  )
}
