import React from 'react';
import { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Grid,
    Divider
} from '@mui/material';
import '../HowWeWork/HowWeWork.css';
import { Icon } from '@iconify/react';
import '../HowWeWork/HowWeWork.css';
import { motion } from 'framer-motion';

export default function HowWeWork({index}) {

  const UnderstandBusinessProblem = require('../../assets/understand-your-business-problem-bg.png');
  const DefineScope = require('../../assets/define-scope-bg.png');
  const UserExperience = require('../../assets/user-experience-bg.png');
  const DesignDevelop = require('../../assets/design-develop-bg.png');
  const TestDevelopment = require('../../assets/testing-deployment-bg.png');

  
  const [display, setDisplay] = useState(0);

  let BoxContent = 
  <Box>
      <Icon className='arrowIcon' icon="material-symbols:arrow-right-alt" width={24} height={24} />
  </Box>;

  let BoxContent1 = 
  <Box>
    <motion.div
    initial={{opacity: 0, y: 0}}
    animate={{opacity: 1, y: 0}}
    transition={{duration: 2, ease: "easeInOut"}}
    >
      <img
      src={String(UnderstandBusinessProblem)}
      alt='understandBusinessProblem'
      />
    </motion.div>
  </Box>;

  let BoxContent2 = 
  <Box>
    <motion.div
    initial={{opacity: 0, y: 0}}
    animate={{opacity: 1, y: 0}}
    transition={{duration: 2, ease: "easeInOut"}}
    >
      <img
      src={String(DefineScope)}
      alt='DefineScope'
      />
    </motion.div>
  </Box>;

  let BoxContent3 = 
  <Box>
    <motion.div
    initial={{opacity: 0, y: 0}}
    animate={{opacity: 1, y: 0}}
    transition={{duration: 2, ease: "easeInOut"}}
    >
      <img
      src={String(UserExperience)}
      alt='UserExperience'
      />
    </motion.div>
  </Box>;

  let BoxContent4 = 
  <Box>
    <motion.div
    initial={{opacity: 0, y: 0}}
    animate={{opacity: 1, y: 0}}
    transition={{duration: 2, ease: "easeInOut"}}
    >
      <img
      src={String(DesignDevelop)}
      alt='DesignDevelop'
      />
    </motion.div>
  </Box>;

  let BoxContent5 = 
  <Box>
    <motion.div
    initial={{opacity: 0, y: 0}}
    animate={{opacity: 1, y: 0}}
    transition={{duration: 2, ease: "easeInOut"}}
    >
      <img
      src={String(TestDevelopment)}
      alt='TestDevelopment'
      />
    </motion.div>
  </Box>;

  const showButton1 = value => {
    setDisplay(value);    
  };
  const showButton2 = value => {
    setDisplay(value);    
  };
  const showButton3 = value => {
    setDisplay(value);    
  };
  const showButton4 = value => {
    setDisplay(value);    
  };
  const showButton5 = value => {
    setDisplay(value);    
  };
 
  return (
    <>
        <section>
            <Box sx={{ py: 8 }}>
                <Container maxWidth="lg">
                    <motion.div
                    initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        y: index % 1 === 0 ? -50 : 50
                      }}
                      whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: 1, // Animation duration
                          ease: "easeOut"
                        }
                      }}
                      viewport={{ once: true }}
                    >
                        <Typography variant='h4' sx={{ fontWeight: '800', color : '#383745', pb : 4, textAlign: 'center' }}>
                            How We <span className='sectionTitle'>Work</span>
                        </Typography>
                    </motion.div>
                    <Box>
                        <Box>
                            <Divider sx={{ my: 2 }} />
                            <Grid className='gridHover' container spacing={2} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onMouseEnter={() => showButton1(1) } onMouseLeave={() => showButton1(0)}>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography variant='h5' sx={{ fontWeight: '500', color : '#383745', display: 'flex', alignItems: 'center' }}>01 <span className='titleWeWork'>Understand Your Business Problem</span> </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography sx={{ color:'#808080' }}>
                                          Deep insights to the business requirements enroutes us to enhance our overall perspection of the requirements for executing the project. Our efforts are focussed predominantly for understanding the Business requirement of the Client.
                                        </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        {display === 1 ? BoxContent1 : BoxContent}
                                    </motion.div>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                        <Box>
                            <Divider sx={{ my: 2 }} />
                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onMouseEnter={() => showButton2(2)} onMouseLeave={() => showButton2(0)}>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography variant='h5' sx={{ fontWeight: '500', color : '#383745', display: 'flex', alignItems: 'center' }}>02 <span className='titleWeWork'>Define and Scope</span> </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography sx={{ color:'#808080' }}>
                                          We perceive the client goals as first and foremost in our scope. To define our scope, we clearly establish the overview, deliverables and limitations in the project. This enables us to materialize expected result in fulfilling client requirements.
                                        </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                      {display === 2 ? BoxContent2 : BoxContent}
                                    </motion.div>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                        <Box>
                            <Divider sx={{ my: 2 }} />
                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onMouseEnter={() => showButton3(3)} onMouseLeave={() => showButton3(0)}>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography variant='h5' sx={{ fontWeight: '500', color : '#383745', display: 'flex', alignItems: 'center' }}>03 <span className='titleWeWork'>User Experience</span> </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography sx={{ color:'#808080' }}>
                                          Our model of work culture is casted on the thumb rule to bind over the End-user. User experience frames the ultimacy in the Client's satisfaction. Meeting with the User perspection yields us the cognitive view of the project
                                        </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        {display === 3 ? BoxContent3 : BoxContent}
                                    </motion.div>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                        <Box>
                            <Divider sx={{ my: 2 }} />
                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onMouseEnter={() => showButton4(4)} onMouseLeave={() => showButton4(0)}>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography variant='h5' sx={{ fontWeight: '500', color : '#383745', display: 'flex', alignItems: 'center' }}>04 <span className='titleWeWork'>Design and Develop</span> </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography sx={{ color:'#808080' }}>
                                          The above-mentioned methodology substantiates our design and development of the project outcome to the best of Client's delight. Level-wise checks facilitae us to deliver the business needs in par with the Client's expectation.
                                        </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                      {display === 4 ? BoxContent4 : BoxContent}
                                    </motion.div>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                        <Box>
                            <Divider sx={{ my: 2 }} />
                            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onMouseEnter={() => showButton5(5)} onMouseLeave={() => showButton5(0)}>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography variant='h5' sx={{ fontWeight: '500', color : '#383745', display: 'flex', alignItems: 'center' }}>05 <span className='titleWeWork'>Testing and Deployment</span> </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography sx={{ color:'#808080' }}>
                                          Quantitative and qualitative driven testing and deployment are assured for our project development and deployment. Appropriate documents for either of the phases are submitted with evidences for a hassle-free implementation.
                                        </Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeOut"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                      {display === 5 ? BoxContent5 : BoxContent}
                                    </motion.div>
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </section>
    </>
  )
}
