import * as React from 'react';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import Main from './components/Main/Main';
import Services from './components/Services/Services';
import Company from './components/Company/Company';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsCondition from './components/TermsCondition/TermsCondition';

function ScrollToTopPage() {
  const { pathname } = useLocation();
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
 
  return null;
}

function App() {
  return (
    <>
      {/* <section> */}
        <Box className='App'>
          <Router>
          {/* <Router basename='/RSWeb'> */}
              <ScrollToTopPage />
              <ScrollToTop smooth color="#0876C1" />
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/explore-our-services" element={<Services />} />
                <Route path="/our-company" element={<Company />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-condition" element={<TermsCondition />} />
              </Routes>
          </Router>
        </Box>      
      {/* </section> */}
    </>
  );
}

export default App;
