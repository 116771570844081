"use client";
import React from 'react';
import { 
    Box,
    Typography,
    Container, 
    Grid, 
    Accordion, 
    AccordionDetails, 
    AccordionSummary 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionIllustration from '../../common/AccordionIllustration/AccordionIllustration';
import '../AboutUs/AboutUs.css';
import AnimatedNumbers from "react-animated-numbers";
import { motion } from 'framer-motion';

  const achievementsList = [
    {
      metric: "Happy Clients",
      value: "250",
    //   postfix: "+",
    },
    {
    //   prefix: "~",
      metric: "Project Completed",
      value: "360",
    },
    {
      metric: "Team Members",
      value: "60",
    },
    {
      metric: "Countries",
      value: "240",
    },
  ];

export default function AboutUs({index}) {

  const [expanded, setExpanded] = React.useState('panel1');
  const [accordImage, setAccordImage] = React.useState('');
 

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);   
    if(panel === "panel1"){
        setAccordImage(require("../../assets/rajasri-services.png"));
    }
    else if(panel === "panel2"){
        setAccordImage(require("../../assets/technical-expertise.png"));
    }
    else if(panel === "panel3"){
        setAccordImage(require("../../assets/business-expertise.png"));
    }
  };

  const ChallengesBg = require('../../assets/challenge-bg.png');

  return (
    <>
        <section id="aboutUs">
            <Box sx={{ py : 8 }}>
                <motion.div
                initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    y: index % 1 === 0 ? -50 : 50
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                      ease: "easeOut"
                    }
                  }}
                  viewport={{ once: true }}
                >
                    <Box sx={{ textAlign : 'center', pb : 8 }}>
                        <Typography className='subHeaderTitle' variant="h4" gutterBottom sx={{ fontWeight: '600', color : '#383745', pb : 2 }}>
                            About <span className='sectionTitle'>Us</span>
                        </Typography>
                        <Container maxWidth="md" sx={{ pb : 3 }}>
                            <Typography  sx={{ color : '#808080' }}>
                            We have been delivering high-quality and reliable software since 2000 with utmost customer satisfaction.
                            Our software is with updated technologies and proves to be a customer delight.
                            </Typography>
                        </Container>
                    </Box>
                </motion.div>
                <Box sx={{ pb : 4 }}>
                    <Container maxWidth="lg">
                        <Grid container rowSpacing={1} columnSpacing={{xs : 1, sm : 6, md : 8}}>
                            <Grid item xs={12} sm={6} className='accordionContent'>
                                <motion.div
                                initial={{
                                    opacity: 0,
                                    // if odd index card,slide from right instead of left
                                    y: index % 1 === 0 ? 50 : -50
                                  }}
                                  whileInView={{
                                    opacity: 1,
                                    y: 0, // Slide in to its original position
                                    transition: {
                                      duration: 1, // Animation duration
                                      ease: "easeOut"
                                    }
                                  }}
                                  viewport={{ once: true }}
                                >
                                    <Box>
                                        <Accordion style={{ boxShadow : 'none' }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ color : '#0876C1' }} />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                                sx={{ flexDirection : 'row-reverse' }}
                                                >
                                                <Typography sx={{ float : 'right', color : '#0876C1', fontWeight : 500, fontSize : '1.25rem', pl : 3 }}>
                                                    Right From the Year 2000
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography sx={{ color : '#808080', pl : 2 }}>
                                                    Clients from various parts of the world have been outsourcing their
                                                    custom software development to us for the past 23 years. Our clients
                                                    range from startups to global companies. They like us because we are 
                                                    agile and are always ready to align ourselves based on their
                                                    requirements. We meet their business needs with quality and best
                                                    resources.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion elevation={0} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ color : '#0876C1' }} />}
                                                aria-controls="panel2bh-content"
                                                id="panel2bh-header"
                                                sx={{ flexDirection : 'row-reverse' }}
                                                >
                                                <Typography sx={{ color : '#0876C1', fontWeight : 500, fontSize : '1.25rem', pl : 3 }}>
                                                    Technical Expertise
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography sx={{ color : '#808080', pl : 2 }}>
                                                    Our programmers are specialized in the latest software technologies: AI solutions, Mobile development tools and frameworks, Microsoft .NET frameworks, Cloud technologies, Database management systems, Web technologies. Our unique competence in various technologies enables us to deliver integrated, scalable, robust solutions with the optimal cost/performance ratio. We constantly conduct research on new technology products to meet the ever-growing needs of our clients. We regularly train our technical team so that they stay on the cutting edge of technology.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion style={{ boxShadow : 'none' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{ color : '#0876C1' }} />}
                                                aria-controls="panel3bh-content"
                                                id="panel3bh-header"
                                                sx={{ flexDirection : 'row-reverse' }}
                                                >
                                                <Typography sx={{ color : '#0876C1', fontWeight : 500, fontSize : '1.25rem', pl : 3 }}>
                                                    Business Domain Expertise
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography sx={{ color : '#808080', pl : 2 }}>
                                                    Our rich business domain expertise enables us to communicate effectively with clients and understand their business needs easily. Our knowledge ranges from application design, development and integration to operating key processes. This knowledge base and expertise help us save client’s precious time and deliver the right solution for every specific situation.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} sm={6} className='illustrationContent' sx={{ margin : 'auto 0', textAlign : 'center' }}>
                                <motion.div
                                initial={{
                                    opacity: 0,
                                    // if odd index card,slide from right instead of left
                                    y: index % 1 === 0 ? -50 : 50
                                  }}
                                  whileInView={{
                                    opacity: 1,
                                    y: 0, // Slide in to its original position
                                    transition: {
                                      duration: 1, // Animation duration
                                      ease: "easeOut"
                                    }
                                  }}
                                  viewport={{ once: true }}
                                >
                                    <AccordionIllustration imageParameter={accordImage} />
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Box sx={{ position: 'relative' }}>
                    <Box 
                    sx={{ 
                        backgroundImage:`url(${ChallengesBg})`, 
                        backgroundRepeat: "no-repeat", 
                        backgroundSize: "cover", 
                        height: "40vh", 
                        width: '100%'
                    }}>
                    </Box>
                    <Box sx={{ position: 'absolute', top: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
                        <Container maxWidth="lg">
                            <Box>
                                <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                                    {achievementsList.map((achievement, index) => {
                                        return (
                                        <Grid item xs={6} sm={3} key={index}>
                                            <Typography variant="h4" sx={{ fontWeight : '500', color : '#FFFFFF', pb : 1 }} 
                                            >
                                                {achievement.prefix}
                                                <AnimatedNumbers
                                                className='animateNumbers'
                                                includeComma
                                                animateToNumber={parseInt(achievement.value)}
                                                locale="en-US"
                                                configs={(_, index) => {
                                                    return {
                                                    mass: 1,
                                                    friction: 100,
                                                    tensions: 140 * (index + 1),
                                                    };
                                                }}
                                                />
                                                {achievement.postfix}
                                            </Typography>
                                            <Typography sx={{ color: '#ffffff' }}
                                            >{achievement.metric}</Typography>
                                        </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Box>
        </section>
    </>
  )
}
