import React from 'react';
import {
    Box,
    Typography,
    Grid,
    Container
} from '@mui/material';
// import { Icon } from '@iconify/react';
import '../WhyRajasri/WhyRajasri.css';
import { motion } from 'framer-motion';

export default function WhyRajasri({index}) {

  const Partnership = require('../../assets/partnership-icon.png');
  const Quality = require('../../assets/quality-icon.png');
  const TrackRecord = require('../../assets/track-reacord-icon.png');
  const Communication = require('../../assets/communication-icon.png');

  return (
    <>
        <section>
            <Box sx={{ py: 8 }}>
                <Container maxWidth="lg">
                    <Box>
                        <Grid container spacing={8} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} sm={12} md={ 4 } className='whyRajasriContent'>
                                <motion.div
                                initial={{
                                    opacity: 0,
                                    // if odd index card,slide from right instead of left
                                    x: index % 1 === 0 ? 50 : -50
                                  }}
                                  whileInView={{
                                    opacity: 1,
                                    x: 0, // Slide in to its original position
                                    transition: {
                                      duration: 1, // Animation duration
                                      ease: "easeIn"
                                    }
                                  }}
                                  viewport={{ once: true }}
                                >
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant='h4' sx={{ fontWeight: '800', color : '#383745', pb : 2 }}>Why <span className='sectionTitle'>Rajasri</span></Typography>
                                        <Typography sx={{ color : '#808080', lineHeight: 2, pt: 2 }}>
                                            In collaboration with Rajasri, achieving the business goals are cost effective, result accredited and customer oriented. Our work policy ensures all these advantage-based criteria.
                                        </Typography>
                                    </Box>
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={ 8 } className='whyRajasriGrid'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeIn"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <Box sx={{ backgroundColor: '#ECF7FF', p: 2, borderRadius: 3 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                                                    <img
                                                    className='WhyRajasriicon'
                                                    src={String(Partnership)}
                                                    alt='Partnership'
                                                    />
                                                    <Typography variant='h5' sx={{ fontWeight: '500', color: '#0876C1', pl: 2, fontSize: '1.25rem' }}>Partnership</Typography>
                                                </Box>
                                                <Typography sx={{ color: '#808080' }}>
                                                    Our credibility and reliability play predominant role in our Customer service. We are transparent to the customer and their requirements. Our customer needs are strictly adhered to the fullest .We never take loyalty for granted.  
                                                </Typography>
                                            </Box>
                                        </motion.div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeIn"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <Box sx={{ backgroundColor: '#ECF7FF', p: 2, borderRadius: 3 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                                                    <img
                                                    className='WhyRajasriicon'
                                                    src={String(Quality)}
                                                    alt='Quality'
                                                    />
                                                    <Typography variant='h5' sx={{ fontWeight: '500', color: '#0876C1', pl: 2, fontSize: '1.25rem' }}>Quality</Typography>
                                                </Box>
                                                    <Typography sx={{ color: '#808080' }}>
                                                        We consistently concentrate on the quality standards starting from requirement phase to completion of the project. The documents pertaining to each stage of the project are created and updated and upon client's request, submitted as a proof of evidence.
                                                </Typography>
                                            </Box>
                                        </motion.div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeIn"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <Box sx={{ backgroundColor: '#ECF7FF', p: 2, borderRadius: 3 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                                                    <img
                                                    className='WhyRajasriicon'
                                                    src={String(TrackRecord)}
                                                    alt='Track Record'
                                                    />
                                                    <Typography variant='h5' sx={{ fontWeight: '500', color: '#0876C1', pl: 2, fontSize: '1.25rem' }}>Track Record</Typography>
                                                </Box>
                                                <Typography sx={{ color: '#808080' }}>
                                                    An uptrend curve from 2001 till date authenticates the projects we have handled. Our track record stays as the proof for our adherance to latest technology, client handling perspection and versatile management of the projects. 
                                                </Typography>
                                            </Box>
                                        </motion.div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeIn"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <Box sx={{ backgroundColor: '#ECF7FF', p: 2, borderRadius: 3 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                                                    <img
                                                    className='WhyRajasriicon'
                                                    src={String(Communication)}
                                                    alt='Communication'
                                                    />
                                                    <Typography variant='h5' sx={{ fontWeight: '500', color: '#0876C1', pl: 2, fontSize: '1.25rem' }}>Communication</Typography>
                                                </Box>
                                                <Typography sx={{ color: '#808080' }}>
                                                    To ensure the expected project delivery, we adopt the effective communication system with our clients. Day-to-day status updates and milestone updates enhances our communication in a projective manner expected as by the corporate clients.
                                                </Typography>
                                            </Box>
                                        </motion.div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </section>
    </>
  )
}
