import React from 'react';
import Header from '../../common/Header/Header';
import CompanyBanner from '../CompanyBanner/CompanyBanner';
import OurClients from '../OurClients/OurClients';
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer';
import OurMission from '../OurMission/OurMission';
import OurGlobalPartner from '../OurGlobalPartner/OurGlobalPartner';
import BoostYourBusiness from '../BoostYourBusiness/BoostYourBusiness';
// import HistoryOfRajasri from '../HistoryOfRajasri/HistoryOfRajasri';

export default function Company() {
  return (
    <>
        <Header />
        <CompanyBanner />
        <OurMission />
        <OurGlobalPartner />
        <BoostYourBusiness />
        {/* <HistoryOfRajasri /> */}
        <OurClients />
        <ContactUs />
        <Footer />
    </>
  )
}
