import React from 'react';
import {
    Box,
    Container,
    Typography
} from '@mui/material';
import Header from '../../common/Header/Header';
import OurClients from '../OurClients/OurClients';
import Footer from '../Footer/Footer';
import ContactUs from '../ContactUs/ContactUs';

export default function TermsCondition() {
  return (
    <>
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ height : '50vh', mt : 14 }}>
                <Typography>Terms and condition component</Typography>
            </Container>
            <OurClients />
            <ContactUs />
            <Footer />
        </Box>
    </>
  )
}
