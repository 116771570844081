import React from 'react';
import {
    Box,
    Container,
    Link,
    Typography
} from '@mui/material';
import '../ServicesBanner/ServicesBanner.css';
import AllButtonComponent from '../../common/AllButtonComponent/AllButtonComponent';
import { motion } from 'framer-motion';

export default function ServicesBanner({index}) {

  const MainBannerImage = require('../../assets/main-banner-bg.png');

  return (
    <>
        <section>
            <Box>
                <Box 
                className="servicesBannerBg"
                sx={{ 
                    backgroundImage:`url(${MainBannerImage})`, 
                    backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover",
                }}>
                    <Box>
                        <Container maxWidth='lg'>
                            <Box className="bannerContent">
                                <Box sx={{ pb : 4, px: 2 }}>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeIn"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography className='servicesBannerTitle' variant='h1' sx={{ fontWeight : 'bold', fontSize: '3rem' }}>
                                            Our
                                        </Typography>
                                        <Typography className='servicesBannerTitle' variant='h1' sx={{ fontWeight : 'bold', fontSize: '3rem' }}>
                                            <span className='titleBusiness'>Services</span>
                                        </Typography>
                                    </motion.div>
                                    <motion.div
                                    initial={{
                                        opacity: 0,
                                        // if odd index card,slide from right instead of left
                                        x: index % 1 === 0 ? 50 : -50
                                      }}
                                      whileInView={{
                                        opacity: 1,
                                        x: 0, // Slide in to its original position
                                        transition: {
                                          duration: 1, // Animation duration
                                          ease: "easeIn"
                                        }
                                      }}
                                      viewport={{ once: true }}
                                    >
                                        <Typography className='servicesBannerContent' sx={{ color : '#808080', lineHeight: 2, pt: 2 }}>
                                          We provide various types of services to different domains and industries. Catering from the minimalistic to the high-end requirements with the latest technology and the readiness for the client support forms the fundamentals for our business services.
                                        </Typography>
                                    </motion.div>
                                </Box>
                                <motion.div
                                initial={{
                                    opacity: 0,
                                    // if odd index card,slide from right instead of left
                                    y: index % 1 === 0 ? -50 : 50
                                  }}
                                  whileInView={{
                                    opacity: 1,
                                    y: 0, // Slide in to its original position
                                    transition: {
                                      duration: 1, // Animation duration
                                      ease: "easeIn"
                                    }
                                  }}
                                  viewport={{ once: true }}
                                >
                                    <Box sx={{ px: 2, display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                        <Link href="#contactUs" sx={{ textDecoration: 'none' }}>
                                          <AllButtonComponent AllButtons="primaryOutlinedButton" buttonValue="Get In Touch" ButtonStyles="outlined" />
                                        </Link>
                                    </Box>
                                </motion.div>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Box>
        </section>
    </>
  )
}
