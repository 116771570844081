import React from 'react';
import {
  Box,
  Typography,
  Container
} from '@mui/material';
import '../CompanyBanner/CompanyBanner.css';
import { motion } from "framer-motion";

export default function CompanyBanner({index}) {

  const MainBannerImage = require('../../assets/main-banner-bg.png');

  return (
    <>
      <section>
        <Box>
          <motion.div
          >
            <Box 
            className="companyBannerBg"
            sx={{ 
              backgroundImage:`url(${MainBannerImage})`, 
              backgroundRepeat: "no-repeat", 
              backgroundSize: "cover", 
              height: "80vh", 
              width: '100%' 
            }}>
              <Box sx={{ textAlign: 'center' }}>
                  <Container maxWidth='lg'>
                      <Box className="bannerContent" sx={{ right: 0, width: '100%' }}>
                        <motion.div
                        initial={{
                          opacity: 0,
                          // if odd index card,slide from right instead of left
                          x: index % 1 === 0 ? 50 : -50
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0, // Slide in to its original position
                          transition: {
                            duration: 1, // Animation duration
                            ease: "easeOut"
                          }
                        }}
                        viewport={{ once: true }}
                        >
                          <Box sx={{ pb : 4, px: 2 }}>
                              <Typography className='headerTitle' variant='h1' sx={{ fontWeight : 'bold', fontSize: '3rem' }}>
                                We Create <span className='titleBusiness'>New Product</span> for
                              </Typography>
                              <Typography className='headerTitle' variant='h1' sx={{ fontWeight : 'bold', fontSize: '3rem' }}>
                                your <span className='titleAmazing'>Business</span> And Goals
                              </Typography>
                          </Box>
                        </motion.div>
                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            // if odd index card,slide from right instead of left
                                            y: index % 1 === 0 ? -50 : 50
                                          }}
                                          whileInView={{
                                            opacity: 1,
                                            y: 0, // Slide in to its original position
                                            transition: {
                                              duration: 1, // Animation duration
                                              ease: "easeIn"
                                            }
                                          }}
                                          viewport={{ once: true }}
                                        >
                                            <Typography sx={{ color : '#808080', lineHeight: 2, px: 4 }}>
                                              We architect the future of your business, products built for your goals.
                                            </Typography>
                                        </motion.div>
                      </Box>
                  </Container>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </section>
    </>
  )
}
