import React from 'react';
import {
    Box,
    Container,
    Link,
    Typography
} from '@mui/material';
import '../PrivacyPolicy/PrivacyPolicy.css';
import Header from '../../common/Header/Header';
import OurClients from '../OurClients/OurClients';
import Footer from '../Footer/Footer';
import ContactUs from '../ContactUs/ContactUs';

export default function PrivacyPolicy() {
  return (
    <>
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ mt : 18, mb : 8 }}>
                <Box>
                  <Typography variant="h5" gutterBottom sx={{ color : '#383745', pb : 2 }}>Privacy Policy</Typography>
                  <Typography sx={{ color : '#808080', pb : 4 }}>
                    Rajasri Systems has created this privacy statement in order to demonstrate its commitment to your privacy.
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="h5" gutterBottom sx={{ color : '#383745', pb : 2 }}>Your Privacy On Rajasri Syatems Website</Typography>
                  <Typography sx={{ color : '#808080', pb : 1 }}>
                    The primary purpose of Rajasri Systems Website (www.rajasri.com) is to be a resource and business
                    tool to help you learn about Rajasri Systems and gain insight into our services.
                  </Typography>
                  <Typography sx={{ color : '#808080', pb : 4 }}>
                    This website is administered by Rajasri Systems. We may use your IP address to identify problems in
                    our server and also to refine the materials based on the usage by our visitors.
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="h5" gutterBottom sx={{ color : '#383745', pb : 2 }}>What Information Do We Gather?</Typography>
                  <Typography sx={{ color : '#383745', pb : 4 }}>Information on this website is gathered in two ways:</Typography>
                  <Box>
                    <Typography variant='subtitle1' sx={{ color : '#808080', pb : 2 }}> 1. When you volunteer to provide some information (e.g. through ‘Contact Us’).</Typography>
                    <Typography sx={{ color : '#808080', pb : 4 }}> 2. Your IP address is automatically collected and is placed in our Internet access logs. The user’s
                    session will be tracked, but the user will be anonymous.</Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography variant="h5" gutterBottom sx={{ color : '#383745', pb : 2 }}> Security Of Your Data</Typography>
                  <Box>
                    <Typography sx={{ color : '#808080', pb : 2 }}>
                      We take appropriate steps to maintain the security of your data on this website.
                    </Typography>
                    <Typography sx={{ color : '#808080', pb : 4 }}>
                      This website has security measures in place to protect the loss, misuse, and/or alteration of
                      information under our control. The data resides behind a firewall, with access restricted to
                      authorized personnel.
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Typography variant="h5" gutterBottom sx={{ color : '#383745', pb : 2 }}> Links</Typography>
                  <Typography sx={{ color : '#808080', pb : 4 }}>
                    This website contains links to other websites. Rajasri Systems is not responsible for the privacy
                    practices or the contents of such websites, nor does Rajasri Systems take any responsibility for the
                    opinions of third parties expressed on or through our website.
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="h5" gutterBottom sx={{ color : '#383745', pb : 2 }}>Contact Us</Typography>
                  <Typography sx={{ color : '#808080' }}>If you have any questions about this privacy statement, please <Link className='PrivacyContactLink' sx={{ color : '#0876C1' }} href="#contactUs">Contact Us.</Link></Typography>
                </Box>
            </Container>
            <OurClients />
            <ContactUs />
            <Footer />
        </Box>
    </>
  )
}
