import React from 'react';
import { Box } from '@mui/material';
import '../AccordionIllustration/AccordionIllustration.css';

export default function AccordionIllustration({imageParameter}) {
    var IllustrationYear = require('../../assets/rajasri-services.png');
 
    if(imageParameter.trim().length > 0){        
        IllustrationYear = imageParameter;
    }  
  return (
    <>
        <section>
            <Box>
                <img
                    className='illustration-about'
                    src={String(IllustrationYear)}
                    alt='Illustration-year'
                />
            </Box>
        </section>
    </>
  )
}
