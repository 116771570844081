import React from 'react';
import Box from '@mui/material/Box';
import { Container, Typography } from '@mui/material';
import '../WhyChooseUs/WhyChooseUs.css';
import { motion } from 'framer-motion';

export default function WhyChooseUs({index}) {

  const NonStopJapanese = require('../../assets/nonstop-accessibility-japanese-bg.png');
  const FullServices = require('../../assets/full-services-bg.png');
  const Partnership = require('../../assets/partnership-reliability-bg.png');
  const ClientLikes = require('../../assets/our-clients-likes-bg.png');
  const backgroundBg = require('../../assets/background-bg.png');

  return (
    <>
      <section>
        <Box sx={{ py: 6 }}>
          <Container maxWidth='lg'>
            <motion.div
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              y: index % 1 === 0 ? 50 : -50
            }}
            whileInView={{
              opacity: 1,
              y: 0, // Slide in to its original position
              transition: {
                duration: 1, // Animation duration
                ease: "easeOut"
              }
            }}
            viewport={{ once: true }}
            >
              <Typography variant='h4' sx={{ fontWeight: '800', color : '#383745', textAlign: 'center', pb: 8 }}>
                Why Choose <span className='sectionTitle'>Us</span>
              </Typography>
            </motion.div>
            <Box className="WhyChooseUs wrapper" sx={{ pt: 4, position: 'relative' }}>
                <Box className='btn two' sx={{ px: 4 }}>
                  <motion.div
                  initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    y: index % 1 === 0 ? -50 : 50
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                      ease: "easeOut"
                    }
                  }}
                  viewport={{ once: true }}
                  >
                    <Box>
                      <img
                      src={String(NonStopJapanese)}
                      alt="nonstopjapanese"
                      />
                    </Box>
                  </motion.div>
                  <motion.div
                  initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    y: index % 1 === 0 ? -50 : 50
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                      ease: "easeOut"
                    }
                  }}
                  viewport={{ once: true }}
                  >
                    <Box sx={{ pt: 8, textAlign: 'center', pb: 4 }}>
                      <Typography variant='h5' sx={{ color: '#0876C1', pb: 2, fontSize: '1.25rem' }}>Nonstop Accessibility Japanese</Typography>
                      <Typography sx={{ color: '#808080' }}>
                        Instant support with accurate solution is what we
                        provide to our clients. Continues communication and
                        regular follow-ups with our client is a part of our
                        process exercise.
                      </Typography>
                    </Box>
                  </motion.div>
                </Box>
              <Box className='btn one' sx={{ px: 4 }}>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut"
                  }
                }}
                viewport={{ once: true }}
                >
                  <Box>
                    <img
                    src={String(FullServices)}
                    alt="FullServices"
                    />
                  </Box>
                </motion.div>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut"
                  }
                }}
                viewport={{ once: true }}
                >
                  <Box sx={{ pt: 8, textAlign: 'center', pb: 4 }}>
                    <Typography variant='h5' sx={{ color: '#0876C1', pb: 2, fontSize: '1.25rem' }}>Full Services</Typography>
                    <Typography sx={{ color: '#808080' }}>
                      <span style={{ fontWeight: '600', color: '#383745'}}>“ONE STOP SHOP”</span> that is what we offer for our
                      clients. We provide End to End process plus support
                      after delivery for each and every client irrelevant to
                      the size of the project.
                    </Typography>
                  </Box>
                </motion.div>
              </Box>
              <Box className='btn three' sx={{ px: 4 }}>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut"
                  }
                }}
                viewport={{ once: true }}
                >
                  <Box>
                    <img
                    src={String(Partnership)}
                    alt="Partnership"
                    />
                  </Box>
                </motion.div>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut"
                  }
                }}
                viewport={{ once: true }}
                >
                  <Box sx={{ pt: 8, textAlign: 'center', pb: 4 }}>
                    <Typography variant='h5' sx={{ color: '#0876C1', pb: 2, fontSize: '1.25rem' }}>Partnership & Realiability</Typography>
                    <Typography sx={{ color: '#808080' }}>
                      Being reliable is an extremely important quality to
                      have, especially in the customer service empire. We
                      let our customer know what we do for them. Tap
                      into what our customer want. Never take loyalty for
                      granted.
                    </Typography>
                  </Box>
                </motion.div>
              </Box>
              <Box className='btn four' sx={{ px: 4 }}>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut"
                  }
                }}
                viewport={{ once: true }}
                >
                  <Box>
                    <img
                    src={String(ClientLikes)}
                    alt="ClientLikes"
                    />
                  </Box>
                </motion.div>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut"
                  }
                }}
                viewport={{ once: true }}
                >
                  <Box sx={{ pt: 8, textAlign: 'center' }}>
                    <Typography variant='h5' sx={{ color: '#0876C1', pb: 2, fontSize: '1.25rem' }}>Our Clients Likes Us</Typography>
                    <Typography sx={{ color: '#808080' }}>
                      We proudly disclose that we have a very healthy
                      Client’s Base. We involve our clients in each and 
                      every part of the project. By this they know
                      100% what is happening in their project in any
                      part of time.
                    </Typography>
                  </Box>
                </motion.div>
              </Box>
            </Box>
              <Box sx={{ position: 'absolute', top: '100px', right: '0px', display: { xs : 'none', sm : 'none', md: 'block' }  }}>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? 50 : -50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeIn"
                  }
                }}
                viewport={{ once: true }}
                >
                    <img
                      className='backgroundBg'
                      src={String(backgroundBg)}
                      alt='leaders-bg'
                    />
                </motion.div>
              </Box>
              <Box sx={{ position: 'absolute', bottom: '60px', display: { xs : 'none', sm : 'none', md: 'block' } }}>
                <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeIn"
                  }
                }}
                viewport={{ once: true }}
                >
                  <img
                    className='backgroundBg'
                    src={String(backgroundBg)}
                    alt='leaders-bg'
                  />
                </motion.div>
              </Box>
            <Box>
            </Box>
          </Container>
        </Box>
      </section>
    </>
  )
}
