import React from "react";
import { Box, IconButton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import "../ToastMessage/ToastMessage.css";
import CloseIcon from "@mui/icons-material/Close";

export default function ToastMessage({
  message,
  IsOpened,
  toastClassName,
  handleClose,
}) {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      {IsOpened ? (
        <Box>
          <Snackbar
            className={toastClassName}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={IsOpened}
            message={message}
            action={action}
          />
        </Box>
      ) : null}
    </>
  );
}
