import React from 'react';
import {
    Box,
    Typography,
    Container,
    Grid
} from '@mui/material';
import { Icon } from '@iconify/react';
import '../ExploreOurAllServices/ExploreOurAllServices.css';
import { motion } from 'framer-motion';

export default function ExploreOurAllServices({index}) {
  return (
    <>
    <section id="exploreOurServices">
        <Box sx={{ pb: 8 }}>
            <motion.div
            initial={{
                opacity: 0,
                // if odd index card,slide from right instead of left
                y: index % 1 === 0 ? -50 : 50
              }}
              whileInView={{
                opacity: 1,
                y: 0, // Slide in to its original position
                transition: {
                  duration: 1, // Animation duration
                  ease: "easeOut"
                }
              }}
              viewport={{ once: true }}
            >
                <Box sx={{ textAlign : 'center', pb: 6 }}>
                    <Typography className='subHeaderTitle' variant="h4" gutterBottom sx={{ fontWeight : '600', color : '#010101', pb : 2 }}>
                        What We <span className='sectionTitle'>Do</span>
                    </Typography>
                </Box>
            </motion.div>
            <Box>
                <Container maxWidth="lg">
                    <motion.div
                    initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        y: index % 1 === 0 ? -50 : 50
                      }}
                      whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: 1, // Animation duration
                          ease: "easeOut"
                        }
                      }}
                      viewport={{ once: true }}
                    >
                        <Grid container spacing={2} className='cardWrapper'>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="carbon:application-mobile" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                        Mobile Application Development
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        Our hard core experience in Mobile app development assures and adaptable solutions. We deliver comprehensive and user-specific mobile apps.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="mdi:microsoft-dot-net" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                        Microsoft.NET
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        Boundless and unlimited expertise in Microsoft.Net, drives us to seek more .Net projects. Our enthusiastic approach in .Net projects has resulted in customer delight.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="carbon:machine-learning" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                        Al/Ml Learning Services
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        Our high-end AI projects are implemented in Hotel, manufacturing, hospital where we have established seamless satisfaction for our Client. 
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="ri:open-source-line" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                        Open Source
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        Designated for broader perspective, the Open source provides optimization, collaboration, proto-typing, community oriented development at a larger scale.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="gravity-ui:database" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                        Data Spider
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        This information integration hub connects the end-to end conveniently with minimal amount of programming,where we configure the requirements and proceed with operational controls.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="icon-park-outline:seo" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                        SEO Services
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        We provide a high-end SEO services based on latest technology and our measures for customer's website is consistent, effective and result-driven. 
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="mdi:web" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                            Website Maintenance Support
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        A key feature that is operated efficiently and maintained in our services. We also undertake Upgrading and Enhancements of existing website.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="material-symbols:desktop-windows-outline-rounded" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                            Windows Application
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        Our portfolio in developing Windows application is extensive. We have great accomplishment where window application holds the best at its performance.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="fluent:phone-desktop-32-regular" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                            Responsive Design
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        This facilitates the comfort of viewing the applications across various devices. This key feature allows the users to adapt to their convenience of perspection.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className='servicesCard'>
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="material-symbols:desktop-mac-outline" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                            Desktop Application
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        The usage of the desktop applications gain value when there is more need of speed, application behaviour, elaborated details and more visibility.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="servicesCard">
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="flowbite:server-outline" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                            Client Server Application
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        Centralized and secured access of the files on Client-Server network are our iconic highlights eventually crafted with robust technology. 
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} className="servicesCard">
                                <Box className="servicesCardContent">
                                    <Typography className='cardTitle'>
                                        <Icon icon="fluent-mdl2:engineering-group" width={36} height={36} />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '1.25rem' }}>
                                            Application Re-Engineering
                                            <Icon icon="solar:arrow-right-broken" width={24} height={24} />
                                        </Box>
                                    </Typography>
                                    <Typography className='contentCardHover'>
                                        We intricate a Comprehensive knowledge of the legacy system to adapt for Re-engineering which results in adaptive solutions.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </motion.div>
                </Container>
            </Box>
        </Box>
    </section>
    </>
  )
}
