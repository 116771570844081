import React from 'react';
import {
    Box,
    Typography,
    Container
} from '@mui/material';
import '../OurGlobalPartner/OurGlobalPartner.css';
import { motion } from 'framer-motion';

export default function OurGlobalPartner({index}) {

  const OutlineQuoteTop = require('../../assets/outline-quote-top.png');
  const OutlineQuoteBottom = require('../../assets/outline-quote-bottom.png');

  return (
    <>
        <section>
            <Box sx={{ py: 8 }}>
                <Container maxWidth="md">
                    <motion.div
                    initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        y: index % 1 === 0 ? 50 : -50
                      }}
                      whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: 1, // Animation duration
                          ease: "easeOut"
                        }
                      }}
                      viewport={{ once: true }}
                    >
                        <Box>
                            <Typography variant='h4' sx={{ fontWeight: '800', color : '#383745', pb : 4, textAlign: 'center' }}>
                                Our Global <span className='sectionTitle'>Partner</span>
                            </Typography>
                        </Box>
                    </motion.div>
                    <Box sx={{ position: 'relative' }}>
                        <motion.div
                        initial={{
                            opacity: 0,
                            // if odd index card,slide from right instead of left
                            y: index % 1 === 0 ? -50 : 50
                          }}
                          whileInView={{
                            opacity: 1,
                            y: 0, // Slide in to its original position
                            transition: {
                              duration: 1, // Animation duration
                              ease: "easeOut"
                            }
                          }}
                          viewport={{ once: true }}
                        >
                            <Box sx={{ backgroundColor: '#ECF7FF', p: 4, borderRadius: '12px' }}>
                                <Typography>
                                    Through our exposure to Japanese projects and our extensive experience in working with numerous Japanese clients,
                                    we've had the privilege of tackling their technical challenges while maintaining an unwavering commitment to quality. Guided by our skilled technical leaders, who have consistently demonstrated their expertise to Japanese clients, we have solidified a valuable partnership in Japan.
                                </Typography>
                                <Typography>
                                    One of our key global partners is Hermits, a Japanese company founded in 2017 with a primary focus on small-scale,
                                    quick-win projects. Over the years, Hermits has evolved into a company that handles a diverse range of projects,
                                    including CRM  solutions using Salesforce, web and mobile app development with Flutter, and various other cutting-edge technologies. The turning point for Hermits came in 2019 when they embarked on an AI project, marking a significant milestone in their journey. This success opened the door to more advanced software projects, and Hermits began taking on numerous similar initiatives, each contributing to their growth.
                                </Typography>
                                <Typography>
                                    In 2021, Hermits made a strategic move by incorporating Outsystems into their portfolio, further accelerating their
                                    expansion and capabilities. One of the standout projects undertaken by our Global Partner was the development of an
                                    online subscription management system for a popular Japanese magazine. As the Indian counterpart for this project,
                                    Rajasri Systems leveraged state-of-the-art technology to exceed the client's expectations in a versatile and
                                    exceptional manner, marking yet another remarkable milestone in our partnership's journey.
                                </Typography>
                            </Box>
                            <Box sx={{ position: 'absolute', top: '-52px', left: 0 }}>
                                <img
                                src={String(OutlineQuoteTop)}
                                alt="OutlineQuoteSymbol"
                                />
                            </Box>
                            <Box sx={{ position: 'absolute', bottom: '-58px', right: 0 }}>
                                <img
                                src={String(OutlineQuoteBottom)}
                                alt="OutlineQuoteSymbol"
                                />
                            </Box>
                        </motion.div>
                    </Box>
                </Container>
            </Box>
        </section>
    </>
  )
}
