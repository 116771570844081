import React from 'react';
import {
    Box,
    Typography,
    Container,
    Link
} from '@mui/material';
import '../Banner/Banner.css';
import AllButtonComponent from '../../common/AllButtonComponent/AllButtonComponent';
import { motion } from "framer-motion";

export default function Banner({index}) {

  const MainBannerImage = require('../../assets/main-banner-bg.png');

  return (
    <>
      <section>
        <Box>
          <Box
          className="mainBannerBg"
          sx={{ 
              backgroundImage:`url(${MainBannerImage})`, 
              backgroundRepeat: "no-repeat", 
              backgroundSize: "cover",
          }}>
              <Box>
                <Container maxWidth="lg">
                    <Box className="TopBannerContent">
                      <Box sx={{ pb : 4, px: 2 }}>
                          <motion.div 
                          initial={{
                              opacity: 0,
                              // if odd index card,slide from right instead of left
                              y: index % 1 === 0 ? -50 : 50
                            }}
                            whileInView={{
                              opacity: 1,
                              y: 0, // Slide in to its original position
                              transition: {
                                duration: 1, // Animation duration
                                ease: "easeIn"
                              }
                            }}
                            viewport={{ once: true }}
                          >                                            
                            <Box>
                              <div id="showcase-dynamic">
                                <div>
                                  <p>
                                    <Typography className='mainTitle' variant='h1' sx={{ fontWeight : 'bold', fontSize: '3rem' }}>
                                      <span className='titleDevelop'>Innovative</span>  IT Solutions for Your
                                      <span className='titleDevelop'> Modern Businesses</span>
                                    </Typography>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <Typography className='mainTitle' variant='h1' sx={{ fontWeight : 'bold', fontSize: '3rem' }}>
                                      <span className='titleAmazing'>Reliable</span> IT Services for
                                      <span className='titleAmazing'> Seamless Operations</span>
                                    </Typography>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <Typography className='mainTitle' variant='h1' sx={{ fontWeight : 'bold', fontSize: '3rem' }}>
                                      <span className='titleBusiness'> Solutions</span> Tailored for <span className='titleBusiness'> You.</span>                                                 
                                    </Typography>
                                  </p>
                                </div>
                              </div>
                            </Box>
                          </motion.div>
                          <motion.div
                          initial={{
                              opacity: 0,
                              // if odd index card,slide from right instead of left
                              y: index % 1 === 0 ? -50 : 50
                            }}
                            whileInView={{
                              opacity: 1,
                              y: 0, // Slide in to its original position
                              transition: {
                                duration: 1, // Animation duration
                                ease: "easeIn"
                              }
                            }}
                            viewport={{ once: true }}
                          >
                            <Typography className='mainBannerContent' sx={{ color : '#808080', lineHeight: 2, pt: 2 }}>
                              We are your trusted guide on the digital transformation journey, <br /> streamlining IT for a more productive future.
                            </Typography>
                          </motion.div>
                      </Box>
                      <motion.div
                                initial={{
                                    opacity: 0,
                                    // if odd index card,slide from right instead of left
                                    y: index % 1 === 0 ? -50 : 50
                                  }}
                                  whileInView={{
                                    opacity: 1,
                                    y: 0, // Slide in to its original position
                                    transition: {
                                      duration: 1, // Animation duration
                                      ease: "easeIn"
                                    }
                                  }}
                                  viewport={{ once: true }}
                                >
                                    <Box sx={{ px: 2, display: { xs: 'block', sm: 'block', md: 'none' } }}>
                                        <Link href="#contactUs" sx={{ textDecoration: 'none' }}>
                                          <AllButtonComponent AllButtons="primaryOutlinedButton" buttonValue="Get In Touch" ButtonStyles="outlined" />
                                        </Link>
                                    </Box>
                                </motion.div>
                    </Box>
                </Container>
              </Box>
          </Box>
        </Box>
      </section>
    </>
  )
}
